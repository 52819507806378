import { render, staticRenderFns } from "./public-ngo-list.html?vue&type=template&id=72446f1a&scoped=true&external"
import script from "./public-ngo-list.js?vue&type=script&lang=js&external"
export * from "./public-ngo-list.js?vue&type=script&lang=js&external"
import style0 from "./public-ngo-list.scss?vue&type=style&index=0&id=72446f1a&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72446f1a",
  null
  
)

export default component.exports